<script setup lang="ts">
export interface Props {
   title: string
   subtitle: string
   description: string
   footer: string
   value?: number | null
   total: string
   max?: number | null
}

const {
   description,
   footer,
   max = 1,
   title,
   total,
   value,
} = defineProps<Props>()

const { t } = useI18n()
</script>

<template>
   <div
      class="flex w-full flex-col gap-2"
   >
      <h3 class="text-lg font-medium">
         <slot />

         <UiModal
            :title="title"
         >
            <template #trigger>
               <UiTooltip>
                  <template #trigger>
                     <Icon
                        name="heroicons-solid:information-circle"
                        class="ml-2 h-5 w-5"
                     />
                     <span class="sr-only">{{ t('global.more-info') }}</span>
                  </template>
                  <div class="max-w-sm">
                     {{ subtitle }}
                  </div>
               </UiTooltip>
            </template>

            <template #description>
               <p
                  class="[&_a]:underline"
                  v-html="description"
               />
            </template>

            <template #footer>
               <p
                  class="[&_a]:underline"
                  v-html="footer"
               />
            </template>
         </UiModal>
      </h3>

      <template v-if="isValidNumber(value) && isValidNumber(max)">
         <ChartsBar
            :value="value"
            :max="max"
            dark
         />

         <div class="flex flex-col gap-2 text-pretty">
            <p class="text-sm">
               {{ t('global.percent-of', {
                  percent: formatPercentage(value / max),
                  total,
               }) }}
            </p>
         </div>
      </template>

      <template v-else>
         <AppComingSoon class="text-white" />
      </template>
   </div>
</template>
