<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'

export interface Props {
   to: RouteLocationRaw
   title: string
   picture: string
}

const { title, to } = defineProps<Props>()

const { t } = useI18n()
</script>

<template>
   <NuxtLinkLocale
      :to="to"
      class="group flex flex-col items-center bg-white"
   >
      <UiLinkTitle class="flex-1 px-4 py-2">
         {{ title }}
      </UiLinkTitle>

      <div class="h-32 w-full overflow-hidden xl:h-48">
         <img
            class="size-full object-cover transition-transform duration-300 group-hover:scale-110"
            :src="picture"
            :alt="t('global.picture-of', { title })"
         >
      </div>
   </NuxtLinkLocale>
</template>
